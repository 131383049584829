// _button-half.scss

// ui-kit-button-half
// screen-medium
// screen-medium:ui-kit-button-half


// ui-kit-button-half
// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-button-half
  .ui-kit-button-half {
    width: calc(50% - #{$spacing-medium} / 2);
  }

  .ui-kit-button-half:only-of-type {
    width: 50%;
  }

}
