// _button-large.scss

// ui-kit-button-large


// ui-kit-button-large
.ui-kit-button-large {
  font-size: $font-size-large;
  height: $size-form-large;
  line-height: round($font-size-large * $ratio-medium);
  padding: 10px $spacing-larger 11px;
}
