// _skeleton-appointment.scss

// ui-kit-skeleton-appointment
// screen-medium
// screen-medium:ui-kit-skeleton-appointment


// ui-kit-skeleton-appointment
.ui-kit-skeleton-appointment {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ui-kit-skeleton-appointment > div:nth-child(1) {
  flex-shrink: 0;
  margin: 0 auto $spacing-medium;
}

.ui-kit-skeleton-appointment > div:nth-child(2) {
  margin: 0 auto;
  max-width: $size-small;
  width: 100%;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-skeleton-appointment
  .ui-kit-skeleton-appointment {
    flex-direction: row;
  }

  .ui-kit-skeleton-appointment > div:nth-child(1) {
    margin: 0 $spacing-medium 0 0;
  }

  .ui-kit-skeleton-appointment > div:nth-child(2) {
    margin: 0;
    max-width: 100%;
    width: calc(100% - (80px + #{$spacing-medium}));
  }

}
