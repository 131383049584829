// _snackbar-bottom.scss

// ui-kit-snackbar-bottom
// ui-kit-snackbar-bottom:ui-kit-snackbar-visible


// ui-kit-snackbar-bottom
.ui-kit-snackbar-bottom {
  bottom: -($size-medium / 2);
  transition: bottom .5s ease-in-out;
}


// ui-kit-snackbar-bottom:ui-kit-snackbar-visible
.ui-kit-snackbar-bottom.ui-kit-snackbar-visible {
  bottom: $spacing-small;
}
