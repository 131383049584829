// _button-full.scss

// screen-medium
// screen-medium:ui-kit-button-full


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-button-full
  .ui-kit-button-full {
    width: 100%;
  }

}
