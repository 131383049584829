// _snackbar-inline.scss

// ui-kit-snackbar-inline
// ui-kit-snackbar-inline:snackbar-actions
// screen-medium
// screen-medium:ui-kit-snackbar-inline
// screen-medium:ui-kit-snackbar-inline:snackbar-content
// screen-medium:ui-kit-snackbar-inline:snackbar-actions


// ui-kit-snackbar-inline
.ui-kit-snackbar-inline {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.ui-kit-snackbar-inline > div {
  width: 100%;
}


// ui-kit-snackbar-inline:snackbar-actions
.ui-kit-snackbar-inline .snackbar-actions {
  margin-top: $spacing-small;
}

.ui-kit-snackbar-inline .snackbar-actions a + a {
  margin-left: $spacing-small;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-snackbar-inline
  .ui-kit-snackbar-inline > div {
    width: auto;
  }


  // screen-medium:ui-kit-snackbar-inline:snackbar-content
  .ui-kit-snackbar-inline .snackbar-content {
    flex: 1;
  }


  // screen-medium:ui-kit-snackbar-inline:snackbar-actions
  .ui-kit-snackbar-inline .snackbar-actions {
    margin-left: $spacing-small;
    margin-top: 0;
  }

}
