// _loader-small.scss

// ui-kit-loader-small


// ui-kit-loader-small
.ui-kit-loader-small,
.ui-kit-loader-small::before,
.ui-kit-loader-small::after {
  height: 8px; // non-standard
  width: 8px; // non-standard
}

.ui-kit-loader-small::before {
  left: -16px; // non-standard
}

.ui-kit-loader-small::after {
  left: 16px; // non-standard
}
