// _input-select.scss

// ui-kit-form:input-select
// ui-kit-form:input-select:untouched|invalid|unselected
// ui-kit-form:input-select:focus
// ui-kit-form:input-select:placeholder


// ui-kit-form:input-select
.ui-kit-form .input-select {
  position: relative;
}

.ui-kit-form .input-select select {
  padding-right: $spacing-larger;
}

.ui-kit-form .input-select i {
  pointer-events: none;
  position: absolute;
  right: $spacing-small;
  top: 50%;
  transform: translate(0, -50%);
}

.ui-kit-form .input-select svg * {
  fill: $color-grey;
}


// ui-kit-form:input-select:untouched|invalid|unselected
.ui-kit-form .input-select .unselected,
.ui-kit-form .input-select .untouched,
.ui-kit-form .input-select .invalid {
  color: $color-grey;
}


// ui-kit-form:input-select:focus
.ui-kit-form .input-select :focus {
  color: $color-primary;
}


// ui-kit-form:input-select:placeholder
.ui-kit-form .hidden ~ .input-select select,
.ui-kit-form .hidden ~ .input-select .unselected,
.ui-kit-form .hidden ~ .input-select .untouched,
.ui-kit-form .hidden ~ .input-select .invalid {
  color: $color-grey-dark;
}

.ui-kit-form .hidden ~ .input-select select:focus,
.ui-kit-form .hidden ~ .input-select .unselected:focus,
.ui-kit-form .hidden ~ .input-select .untouched:focus,
.ui-kit-form .hidden ~ .input-select .invalid:focus {
  color: $color-primary;
}

.ui-kit-form .hidden ~ .input-select .selected {
  color: $color-primary;
}
