// _status.scss

// ui-kit-status


// ui-kit-status
.ui-kit-status {
  background: $color-grey-light;
  border-radius: $border-radius-small $border-radius-large $border-radius-large $border-radius-small;
  display: inline-block;
  padding: $spacing-smaller $spacing-medium $spacing-smaller $spacing-small;
}

.ui-kit-status p {
  color: $color-primary;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-small);
}
