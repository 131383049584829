// _notification.scss

// ui-kit-notification
// ui-kit-notification-close
// screen-large
// screen-large:ui-kit-notification-close


// ui-kit-notification
.ui-kit-notification {
  border-radius: $border-radius-medium;
  padding: $spacing-medium $spacing-large $spacing-medium $spacing-medium;
  position: relative;
}

.ui-kit-notification p {
  color: $color-primary;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}


// ui-kit-notification-close
.ui-kit-notification-close {
  position: absolute;
  right: 0;
  top: 0;
}

.ui-kit-notification-close a svg * {
  fill: $color-grey-dark;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-notification-close
  .ui-kit-notification-close {
    right: $spacing-smaller;
    top: $spacing-smaller;
  }

}
