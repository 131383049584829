// _calendar-past.scss

// ui-kit-calendar-past


// ui-kit-calendar-past
.ui-kit-calendar-past .ui-kit-calendar-month {
  background: $color-grey;
}

.ui-kit-calendar-past .ui-kit-calendar-day {
  color: $color-grey;
}
