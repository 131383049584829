// _cards.scss

// ui-kit-cards
// screen-large
// screen-large:ui-kit-cards


// ui-kit-cards
.ui-kit-cards {
  display: flex;
  flex-wrap: wrap;
  margin: -($spacing-small / 2);
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-cards
  .ui-kit-cards {
    margin: -($spacing-large / 2);
  }

}
