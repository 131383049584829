// _overlay-appointment.scss

// ui-kit-overlay-appointment
// ui-kit-overlay-appointment:overlay-appointment-success
// ui-kit-overlay-appointment:overlay-appointment-header
// ui-kit-overlay-appointment:overlay-appointment-content
// ui-kit-overlay-appointment:overlay-appointment-calendar
// ui-kit-overlay-appointment:overlay-appointment-details
// ui-kit-overlay-appointment:overlay-appointment-message
// ui-kit-overlay-appointment:overlay-appointment-reason
// ui-kit-overlay-appointment:overlay-appointment-buttons
// screen-medium
// screen-medium:ui-kit-overlay-appointment:overlay-appointment-success
// screen-medium:ui-kit-overlay-appointment:overlay-appointment-header
// screen-medium:ui-kit-overlay-appointment:overlay-appointment-content
// screen-medium:ui-kit-overlay-appointment:overlay-appointment-calendar
// screen-medium:ui-kit-overlay-appointment:overlay-appointment-buttons


// ui-kit-overlay-appointment
.ui-kit-overlay-appointment h3 {
  font-size: $font-size-larger;
  font-weight: 900;
  line-height: round($font-size-larger * $ratio-medium);
}

.ui-kit-overlay-appointment h4 {
  font-size: $font-size-large;
  font-weight: 400;
  line-height: round($font-size-large * $ratio-medium);
}

.ui-kit-overlay-appointment p {
  color: $color-primary;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}


// ui-kit-overlay-appointment:overlay-appointment-success
.ui-kit-overlay-appointment .overlay-appointment-success {
  box-shadow: $box-shadow-normal;
  margin-left: -($spacing-small);
  margin-right: -($spacing-small);
  margin-top: -($spacing-larger + $spacing-smaller);
  padding: $spacing-medium $spacing-small;
}

.ui-kit-overlay-appointment .overlay-appointment-success p {
  font-size: $font-size-large;
  font-weight: 700;
  line-height: ($size-icon-medium * 2);
  text-align: center;
}

.ui-kit-overlay-appointment .overlay-appointment-success i,
.ui-kit-overlay-appointment .overlay-appointment-success svg {
  height: ($size-icon-medium * 2);
  width: ($size-icon-medium * 2);
}

.ui-kit-overlay-appointment .overlay-appointment-success i {
  margin-right: $spacing-small;
}

.ui-kit-overlay-appointment .overlay-appointment-success svg * {
  fill: $color-status-success;
}


// ui-kit-overlay-appointment:overlay-appointment-header
.ui-kit-overlay-appointment .overlay-appointment-header {
  text-align: center;
}


// ui-kit-overlay-appointment:overlay-appointment-content
.ui-kit-overlay-appointment .overlay-appointment-content {
  margin-top: $spacing-medium;
  text-align: center;
}

.ui-kit-overlay-appointment .overlay-appointment-content h4 {
  color: $color-grey-dark;
}

.ui-kit-overlay-appointment .overlay-appointment-content form {
  margin-top: $spacing-large;
}

.ui-kit-overlay-appointment .overlay-appointment-content p + p {
  margin-top: $spacing-small;
}


// ui-kit-overlay-appointment:overlay-appointment-calendar
.ui-kit-overlay-appointment .overlay-appointment-calendar:nth-of-type(1) {
  margin-top: -($spacing-larger + $spacing-smaller) + $spacing-large;
}

.ui-kit-overlay-appointment .overlay-appointment-calendar:nth-of-type(2) {
  margin-top: $spacing-large;
}

.ui-kit-overlay-appointment .overlay-appointment-calendar i {
  margin: 0 auto;
}


// ui-kit-overlay-appointment:overlay-appointment-details
.ui-kit-overlay-appointment .overlay-appointment-details {
  margin-top: $spacing-medium;
  text-align: center;
}

.ui-kit-overlay-appointment .overlay-appointment-details h4 {
  margin-top: $spacing-smaller;
}


// ui-kit-overlay-appointment:overlay-appointment-message
.ui-kit-overlay-appointment .overlay-appointment-message {
  border-bottom: 1px solid $color-grey-light;
  margin-top: $spacing-small;
  padding-bottom: ($spacing-large - 1px);
  text-align: center;
}

.ui-kit-overlay-appointment .overlay-appointment-message + .overlay-appointment-message {
  margin-top: $spacing-large;
}

.ui-kit-overlay-appointment .overlay-appointment-message p {
  color: $color-grey-dark;
  line-height: round($font-size-medium * $ratio-small);
}

.ui-kit-overlay-appointment .overlay-appointment-message p > i {
  margin-right: $spacing-smaller;
}

.ui-kit-overlay-appointment .overlay-appointment-message p > i svg * {
  fill: $color-grey;
}

.ui-kit-overlay-appointment .overlay-appointment-message > p + p {
  margin-top: $spacing-smaller;
}

.ui-kit-overlay-appointment .overlay-appointment-message > * + * {
  margin-top: $spacing-small;
}


// ui-kit-overlay-appointment:overlay-appointment-reason
.ui-kit-overlay-appointment .overlay-appointment-reason {
  margin-top: $spacing-large;
}

.ui-kit-overlay-appointment .overlay-appointment-reason p {
  color: $color-grey-dark;
}

.ui-kit-overlay-appointment .overlay-appointment-reason p + p {
  margin-top: $spacing-small;
}

.ui-kit-overlay-appointment .overlay-appointment-reason p span {
  color: $color-primary;
  font-weight: 700;
}


// ui-kit-overlay-appointment:overlay-appointment-buttons
.ui-kit-overlay-appointment .overlay-appointment-buttons {
  font-size: 0;
  line-height: 0;
  margin-top: $spacing-larger;
}

.ui-kit-overlay-appointment .overlay-appointment-buttons + .overlay-appointment-buttons {
  margin-top: $spacing-small;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-overlay-appointment:overlay-appointment-success
  .ui-kit-overlay-appointment .overlay-appointment-success {
    margin-left: -($spacing-larger + $spacing-smaller);
    margin-right: -($spacing-larger + $spacing-smaller);
    margin-top: -($spacing-large * 2);
    padding-left: ($spacing-larger + $spacing-smaller);
    padding-right: ($spacing-larger + $spacing-smaller);
  }


  // screen-medium:ui-kit-overlay-appointment:overlay-appointment-header
  .ui-kit-overlay-appointment .overlay-appointment-header {
    text-align: left;
  }


  // screen-medium:ui-kit-overlay-appointment:overlay-appointment-content
  .ui-kit-overlay-appointment .overlay-appointment-content {
    text-align: left;
  }


  // screen-medium:ui-kit-overlay-appointment:overlay-appointment-calendar
  .ui-kit-overlay-appointment .overlay-appointment-calendar:nth-of-type(1) {
    margin-top: -($spacing-large * 2) + $spacing-larger;
  }

  .ui-kit-overlay-appointment .overlay-appointment-calendar:nth-of-type(2) {
    margin-top: $spacing-larger;
  }


  // screen-medium:ui-kit-overlay-appointment:overlay-appointment-buttons
  .ui-kit-overlay-appointment .overlay-appointment-buttons + .overlay-appointment-buttons {
    margin-top: $spacing-medium;
  }

}
