// _icon-medium.scss

// ui-kit-icon-medium


// ui-kit-icon-medium
.ui-kit-icon-medium,
.ui-kit-icon-medium svg {
  height: $size-icon-medium;
  width: $size-icon-medium;
}
