// _button-primary.scss

// ui-kit-button-primary
// ui-kit-button-primary:animation
// screen-large
// screen-large:ui-kit-button-primary


// ui-kit-button-primary
.ui-kit-button-primary {
  background: $color-secondary;
  color: $color-white;
  transition: background .3s ease-in-out;
}

.ui-kit-button-primary > em,
.ui-kit-button-primary > em::before,
.ui-kit-button-primary > em::after {
  animation-name: ui-kit-button-primary;
  background: rgba($color-white, .25);
}

.ui-kit-button-primary svg * {
  fill: $color-white;
}

.ui-kit-button-primary:focus,
.ui-kit-button-primary:active {
  background: darken($color-secondary, $percentage-hover);
  color: $color-white;
}


// ui-kit-button-primary:animation
@keyframes ui-kit-button-primary {
  0% { background: rgba($color-white, .25); }
  50% { background: rgba($color-white, 1); }
  90% { background: rgba($color-white, .25); }
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-button-primary
  .ui-kit-button-primary:hover {
    background: lighten($color-secondary, $percentage-hover);
    color: $color-white;
  }

  .ui-kit-button-primary:focus,
  .ui-kit-button-primary:active {
    background: darken($color-secondary, $percentage-hover);
    color: $color-white;
  }

}
