// _tabs.scss

// ui-kit-tabs
// ui-kit-tabs-list
// ui-kit-tabs-item
// ui-kit-tabs-item-selected
// screen-large
// screen-large:ui-kit-tabs-item


// ui-kit-tabs
// ui-kit-tabs-list
.ui-kit-tabs-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 ($spacing-small / -2);
  padding: 0;
  white-space: nowrap;
}


// ui-kit-tabs-item
.ui-kit-tabs-item {
  cursor: pointer;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-small);
  list-style-type: none;
  margin: 0 ($spacing-small / 2);
  padding: $spacing-smaller $spacing-smaller ($spacing-smaller + 2px);
  position: relative;
  transition: color .3s ease-in-out;
}

.ui-kit-tabs-item::after {
  background: $color-secondary;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity .3s ease-in-out;
  width: 100%;
}


// ui-kit-tabs-item-selected
.ui-kit-tabs-item-selected {
  color: $color-secondary;
  pointer-events: none;
}

.ui-kit-tabs-item-selected::after {
  opacity: 1;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-tabs-item
  .ui-kit-tabs-item:hover {
    color: $color-secondary;
  }

}
