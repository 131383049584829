// _button-blueline.scss

// ui-kit-button-blueline
// ui-kit-button-blueline:animation
// screen-large
// screen-large:ui-kit-button-blueline


// ui-kit-button-blueline
.ui-kit-button-blueline {
  background: transparent;
  color: $color-accent;
  transition: color .3s ease-in-out, background .3s ease-in-out;
}

.ui-kit-button-blueline::after {
  border: 1px solid $color-accent;
  border-radius: $border-radius-large;
  box-sizing: content-box;
  content: '';
  height: calc(100% - 2px);
  left: 0;
  position: absolute;
  top: 0;
  transition: border .3s ease-in-out;
  width: calc(100% - 2px);
}

.ui-kit-button-blueline > em,
.ui-kit-button-blueline > em::before,
.ui-kit-button-blueline > em::after {
  animation-name: ui-kit-button-blueline;
  background: rgba($color-accent, .25);
}

.ui-kit-button-blueline svg * {
  fill: $color-accent;
  transition: fill .3s ease-in-out;
}

.ui-kit-button-blueline:focus,
.ui-kit-button-blueline:active {
  background: darken($color-accent, $percentage-hover);
  color: $color-white;
}

.ui-kit-button-blueline:focus::after,
.ui-kit-button-blueline:active::after {
  border-color: darken($color-accent, $percentage-hover);
}

.ui-kit-button-blueline:focus svg *,
.ui-kit-button-blueline:active svg * {
  fill: $color-white;
}


// ui-kit-button-blueline:animation
@keyframes ui-kit-button-blueline {
  0% { background: rgba($color-accent, .25); }
  50% { background: rgba($color-accent, 1); }
  90% { background: rgba($color-accent, .25); }
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-button-blueline
  .ui-kit-button-blueline:hover {
    background: lighten($color-accent, $percentage-hover);
    color: $color-white;
  }

  .ui-kit-button-blueline:hover::after {
    border-color: lighten($color-accent, $percentage-hover);
  }

  .ui-kit-button-blueline:focus,
  .ui-kit-button-blueline:active {
    background: darken($color-accent, $percentage-hover);
    color: $color-white;
  }

  .ui-kit-button-blueline:focus::after,
  .ui-kit-button-blueline:active::after {
    border-color: darken($color-accent, $percentage-hover);
  }

  .ui-kit-button-blueline:hover svg * {
    fill: $color-white;
  }

}
