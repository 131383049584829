// _icon.scss

// ui-kit-icon


// ui-kit-icon
.ui-kit-icon {
  position: relative;
}

.ui-kit-icon svg {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
