// _input-checkbox-medium.scss

// ui-kit-form:input-checkbox-medium


// ui-kit-form:input-checkbox-medium
.ui-kit-form .input-checkbox-medium label {
  padding-left: ($spacing-smaller + $spacing-smaller / 2 + 22px);
}

.ui-kit-form .input-checkbox-medium span::before {
  height: 20px;
  left: 0;
  top: 1px;
  width: 20px;
}
