// _loader-medium.scss

// ui-kit-loader-medium


// ui-kit-loader-medium
.ui-kit-loader-medium,
.ui-kit-loader-medium::before,
.ui-kit-loader-medium::after {
  height: 12px; // non-standard
  width: 12px; // non-standard
}

.ui-kit-loader-medium::before {
  left: -24px; // non-standard
}

.ui-kit-loader-medium::after {
  left: 24px; // non-standard
}
