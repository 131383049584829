// _input-search-filter.scss

// ui-kit-form:input-search-filter
// screen-large
// screen-large:ui-kit-form:input-search-filter


// ui-kit-form:input-search-filter
.ui-kit-form .input-search-filter i svg * {
  fill: $color-grey-dark;
}

.ui-kit-form .input-search-filter > i {
  left: $spacing-small;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ui-kit-form .input-search-filter > i ~ input {
  padding-left: ($size-icon-large + $spacing-small + $spacing-smaller);
}

.ui-kit-form .input-search-filter > span {
  cursor: pointer;
  height: $size-boundingbox-larger;
  position: absolute;
  right: ($spacing-small - 13px);
  top: 50%;
  transform: translate(0, -50%);
  width: $size-boundingbox-larger;
}

.ui-kit-form .input-search-filter > span i {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ui-kit-form .input-search-filter > span svg * {
  transition: fill .3s ease-in-out;
}

.ui-kit-form .input-search-filter input {
  padding-right: ($size-boundingbox-larger + ($spacing-smaller / 2) + $spacing-smaller);
}

.ui-kit-form .input-search-filter input::placeholder {
  color: $color-grey-dark;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-form:input-search-filter
  .ui-kit-form .input-search-filter > span {
    height: $size-boundingbox-medium;
    right: ($spacing-small - 3px);
    width: $size-boundingbox-medium;
  }

  .ui-kit-form .input-search-filter > span:hover svg * {
    fill: lighten($color-grey-dark, $percentage-hover);
  }

  .ui-kit-form .input-search-filter > span:focus svg *,
  .ui-kit-form .input-search-filter > span:active svg * {
    fill: darken($color-grey-dark, $percentage-hover);
  }

  .ui-kit-form .input-search-filter input {
    padding-right: ($size-boundingbox-medium + $spacing-small + $spacing-smaller);
  }

}
