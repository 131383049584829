// _breadcrumbs.scss

// ui-kit-breadcrumbs
// ui-kit-breadcrumbs-label
// ui-kit-breadcrumbs-separator
// ui-kit-breadcrumbs-link
// ui-kit-breadcrumbs-active
// screen-large
// screen-large:ui-kit-breadcrumbs-link


// ui-kit-breadcrumbs
.ui-kit-breadcrumbs ul {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.ui-kit-breadcrumbs li {
  list-style-type: none;
  padding: 0;
}


// ui-kit-breadcrumbs-label
.ui-kit-breadcrumbs-label {
  margin: 0;
}


// ui-kit-breadcrumbs-separator
.ui-kit-breadcrumbs-separator {
  margin: 0 $spacing-smaller;
}

.ui-kit-breadcrumbs-separator i,
.ui-kit-breadcrumbs-separator svg {
  height: ($size-icon-smaller - 2px);
  width: ($size-icon-smaller - 2px);
}

.ui-kit-breadcrumbs-separator svg * {
  fill: $color-primary;
}


// ui-kit-breadcrumbs-link
.ui-kit-breadcrumbs-link {
  color: $color-secondary;
  display: block;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-medium);
}

.ui-kit-breadcrumbs-link:focus,
.ui-kit-breadcrumbs-link:active {
  color: darken($color-secondary, $percentage-hover);
}


// ui-kit-breadcrumbs-active
.ui-kit-breadcrumbs-active,
.ui-kit-breadcrumbs-active:hover,
.ui-kit-breadcrumbs-active:focus,
.ui-kit-breadcrumbs-active:active {
  color: $color-primary;
  pointer-events: none;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-breadcrumbs-link
  .ui-kit-breadcrumbs-link:hover {
    color: lighten($color-secondary, $percentage-hover);
  }

  .ui-kit-breadcrumbs-link:focus,
  .ui-kit-breadcrumbs-link:active {
    color: darken($color-secondary, $percentage-hover);
  }

}
