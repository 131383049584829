// _no-results.scss

// ui-kit-no-results
// ui-kit-no-results-wrapper
// screen-large
// screen-large:ui-kit-no-results-wrapper


// ui-kit-no-results
.ui-kit-no-results {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  min-height: ($spacing-larger * 5);
  text-align: center;
}

.ui-kit-no-results h2 {
  font-size: $font-size-large;
  font-weight: 700;
  line-height: round($font-size-large * $ratio-medium);
}


// ui-kit-no-results-wrapper
.ui-kit-no-results-wrapper {
  width: 100%;
}

.ui-kit-no-results-wrapper > a,
.ui-kit-no-results-wrapper > button {
  margin-top: $spacing-medium;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-no-results-wrapper
  .ui-kit-no-results-wrapper {
    margin-top: -($spacing-large);
  }

}
