// _card-appointment.scss

// ui-kit-card-appointment
// ui-kit-card-appointment:card-appointment-content
// ui-kit-card-appointment:card-appointment-future
// ui-kit-card-appointment:card-appointment-past
// ui-kit-card-appointment:card-appointment-calendar
// ui-kit-card-appointment:card-appointment-details
// ui-kit-card-appointment:card-appointment-more
// ui-kit-card-appointment:card-appointment-contextual
// ui-kit-card-appointment:card-appointment-video
// screen-medium
// screen-medium:ui-kit-card-appointment:card-appointment-calendar
// screen-medium:ui-kit-card-appointment:card-appointment-details
// screen-medium:ui-kit-card-appointment:card-appointment-video
// screen-large
// screen-large:ui-kit-card-appointment:card-appointment-more
// screen-large:ui-kit-card-appointment:card-appointment-contextual
// screen-large:ui-kit-card-appointment:card-appointment-video


// ui-kit-card-appointment
.ui-kit-card-appointment {
  transition: box-shadow .3s ease-in-out;
}

.ui-kit-card-appointment > a {
  display: block;
  font-weight: 400;
}


// ui-kit-card-appointment:card-appointment-content
.ui-kit-card-appointment .card-appointment-content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}


// ui-kit-card-appointment:card-appointment-future
.ui-kit-card-appointment .card-appointment-future {
  color: $color-primary;
  padding: ($spacing-larger + $spacing-small) $spacing-larger;
}


// ui-kit-card-appointment:card-appointment-past
.ui-kit-card-appointment .card-appointment-past {
  color: $color-grey-dark;
  padding: $spacing-larger;
}


// ui-kit-card-appointment:card-appointment-calendar
.ui-kit-card-appointment .card-appointment-calendar {
  flex-shrink: 0;
  margin-bottom: $spacing-medium;
  width: 100%;
}

.ui-kit-card-appointment .card-appointment-calendar i {
  margin: 0 auto;
}


// ui-kit-card-appointment:card-appointment-details
.ui-kit-card-appointment .card-appointment-details {
  height: (($font-size-medium * $ratio-medium) * 3 + ($font-size-large * $ratio-medium) + ($spacing-smaller / 2));
  margin: 0 auto;
  max-width: 100%;
  width: auto;
}

.ui-kit-card-appointment .card-appointment-details h3,
.ui-kit-card-appointment .card-appointment-details p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui-kit-card-appointment .card-appointment-details h3 {
  font-size: $font-size-large;
  font-weight: 900;
  line-height: round($font-size-large * $ratio-medium);
}

.ui-kit-card-appointment .card-appointment-details p {
  font-size: $font-size-medium;
  font-weight: 400;
  line-height: round($font-size-medium * $ratio-medium);
}

.ui-kit-card-appointment .card-appointment-details p:nth-of-type(3) {
  color: $color-grey-dark;
  margin-top: ($spacing-smaller / 2);
}

.ui-kit-card-appointment .card-appointment-details i {
  margin-right: $spacing-smaller;
}

.ui-kit-card-appointment .card-appointment-details svg * {
  fill: $color-grey;
}


// ui-kit-card-appointment:card-appointment-more
.ui-kit-card-appointment .card-appointment-more {
  bottom: ($spacing-small - ($spacing-smaller / 2));
  left: 0;
  opacity: 0;
  position: absolute;
  transition: bottom .3s ease-in-out, opacity .3s ease-in-out;
  width: 100%;
}

.ui-kit-card-appointment .card-appointment-more p {
  color: $color-secondary;
  font-size: $font-size-smaller;
  font-weight: 700;
  line-height: round($font-size-smaller * $ratio-small);
  text-align: center;
  text-transform: uppercase;
}


// ui-kit-card-appointment:card-appointment-contextual
.ui-kit-card-appointment .card-appointment-contextual {
  position: absolute;
  top: 0;
}

.ui-kit-card-appointment .card-appointment-contextual:nth-of-type(1) {
  right: 0;
}

.ui-kit-card-appointment .card-appointment-contextual:nth-of-type(2) {
  right: $size-boundingbox-larger;
}

.ui-kit-card-appointment .card-appointment-contextual:nth-of-type(2) > div > div > p {
  white-space: nowrap;
}


// ui-kit-card-appointment:card-appointment-video
.ui-kit-card-appointment .card-appointment-video {
  bottom: ($spacing-larger + $spacing-small);
  left: $spacing-larger;
  position: absolute;
  text-align: center;
  width: calc(100% - #{$spacing-larger} * 2);
}

.ui-kit-card-appointment .card-appointment-video button {
  background: transparent;
  border: 0;
  color: $color-secondary;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-medium);
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  text-align: left;
  text-overflow: ellipsis;
  transition: color .3s ease-in-out;
  white-space: nowrap;
}

.ui-kit-card-appointment .card-appointment-video button:focus,
.ui-kit-card-appointment .card-appointment-video button:active {
  color: darken($color-secondary, $percentage-hover);
}

.ui-kit-card-appointment .card-appointment-video button:focus svg *,
.ui-kit-card-appointment .card-appointment-video button:active svg * {
  fill: darken($color-secondary, $percentage-hover);
}

.ui-kit-card-appointment .card-appointment-video i {
  margin-right: $spacing-smaller;
}

.ui-kit-card-appointment .card-appointment-video svg * {
  fill: $color-secondary;
  transition: fill .3s ease-in-out;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-card-appointment:card-appointment-calendar
  .ui-kit-card-appointment .card-appointment-calendar {
    margin-bottom: 0;
    margin-right: $spacing-medium;
    width: 80px;
  }


  // screen-medium:ui-kit-card-appointment:card-appointment-details
  .ui-kit-card-appointment .card-appointment-details {
    width: calc(100% - (80px + #{$spacing-medium}));
  }


  // screen-medium:ui-kit-card-appointment:card-appointment-video
  .ui-kit-card-appointment .card-appointment-video {
    left: ($spacing-larger + $spacing-medium + 80px);
    max-width: calc(100% - (#{$spacing-larger} * 2 + 80px + #{$spacing-medium}));
    text-align: left;
    width: auto;
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-card-appointment
  .ui-kit-card-appointment:hover {
    box-shadow: $box-shadow-hover;
  }


  // screen-large:ui-kit-card-appointment:card-appointment-more
  .ui-kit-card-appointment > a:hover .card-appointment-more {
    bottom: $spacing-small;
    opacity: 1;
  }


  // screen-large:ui-kit-card-appointment:card-appointment-contextual
  .ui-kit-card-appointment .card-appointment-contextual {
    top: ($spacing-medium / 2);
  }

  .ui-kit-card-appointment .card-appointment-contextual:nth-of-type(1) {
    right: ($spacing-medium / 2);
  }

  .ui-kit-card-appointment .card-appointment-contextual:nth-of-type(2) {
    right: ($spacing-medium / 2 + $size-icon-larger + $spacing-smaller);
  }


  // screen-large:ui-kit-card-appointment:card-appointment-video
  .ui-kit-card-appointment .card-appointment-video button:hover {
    color: lighten($color-secondary, $percentage-hover);
  }

  .ui-kit-card-appointment .card-appointment-video button:hover svg * {
    fill: lighten($color-secondary, $percentage-hover);
  }

  .ui-kit-card-appointment .card-appointment-video button:focus,
  .ui-kit-card-appointment .card-appointment-video button:active {
    color: darken($color-secondary, $percentage-hover);
  }

  .ui-kit-card-appointment .card-appointment-video button:focus svg *,
  .ui-kit-card-appointment .card-appointment-video button:active svg * {
    fill: darken($color-secondary, $percentage-hover);
  }

}
