// _input-label-hidden.scss

// ui-kit-form:input-label-hidden
// ui-kit-form:input-label-hidden:placeholder:base
// ui-kit-form:input-label-hidden:placeholder:select


// ui-kit-form:input-label-hidden
.ui-kit-form .input-label-hidden {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}


// ui-kit-form:input-label-hidden:placeholder:base
.ui-kit-form .input-label-hidden ~ div ::placeholder {
  color: $color-grey-dark;
}


// ui-kit-form:input-label-hidden:placeholder:select
.ui-kit-form .input-label-hidden ~ .input-select select,
.ui-kit-form .input-label-hidden ~ .input-select .unselected,
.ui-kit-form .input-label-hidden ~ .input-select .untouched,
.ui-kit-form .input-label-hidden ~ .input-select .invalid {
  color: $color-grey-dark;
}

.ui-kit-form .input-label-hidden ~ .input-select select:focus,
.ui-kit-form .input-label-hidden ~ .input-select .unselected:focus,
.ui-kit-form .input-label-hidden ~ .input-select .untouched:focus,
.ui-kit-form .input-label-hidden ~ .input-select .invalid:focus {
  color: $color-primary;
}

.ui-kit-form .input-label-hidden ~ .input-select .selected {
  color: $color-primary;
}
