// _card-triage.scss

// ui-kit-card-triage
// ui-kit-card-triage:card-triage-icon
// ui-kit-card-triage:card-triage-content
// ui-kit-card-triage:card-triage-header
// ui-kit-card-triage:card-triage-description
// screen-large
// screen-large:ui-kit-card-triage


// ui-kit-card-triage
.ui-kit-card-triage {
  transition: box-shadow .3s ease-in-out;
}

.ui-kit-card-triage a {
  display: block;
  font-weight: 400;
  height: 100%;
}

.ui-kit-card-triage h4,
.ui-kit-card-triage p {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}

.ui-kit-card-triage h4 {
  color: $color-primary;
}

.ui-kit-card-triage p {
  color: $color-grey-dark;
}


// ui-kit-card-triage:card-triage-icon
.ui-kit-card-triage .card-triage-icon {
  bottom: $spacing-small;
  pointer-events: none;
  position: absolute;
  right: $spacing-small;
}

.ui-kit-card-triage .card-triage-icon ~ .card-triage-content,
.ui-kit-card-triage .card-triage-icon ~ a .card-triage-content {
  padding-right: $spacing-large;
}

.ui-kit-card-triage .card-triage-icon .icon-launch * {
  fill: $color-grey;
}

.ui-kit-card-triage .card-triage-icon .icon-ce * {
  fill: $color-black;
}


// ui-kit-card-triage:card-triage-content
.ui-kit-card-triage .card-triage-content {
  padding: $spacing-medium;
}


// ui-kit-card-triage:card-triage-header
// ui-kit-card-triage:card-triage-description
.ui-kit-card-triage .card-triage-description {
  margin-top: $spacing-smaller;
  max-height: round($font-size-medium * $ratio-medium) * 3;
  overflow: hidden;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-card-triage
  .ui-kit-card-triage:hover {
    box-shadow: $box-shadow-hover;
  }

}
