// _link-icon-smaller.scss

// ui-kit-link-icon-smaller


// ui-kit-link-icon-smaller
.ui-kit-link-icon-smaller svg {
  height: $size-icon-smaller;
  width: $size-icon-smaller;
}
