// _input-radio.scss

// ui-kit-form:input-radio
// ui-kit-form:input-radio:focus


// ui-kit-form:input-radio
.ui-kit-form .input-radio + .input-radio {
  margin-top: $spacing-small;
}

.ui-kit-form .input-radio label {
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 0;
  padding-left: ($spacing-smaller + $spacing-smaller / 2 + 22px);
  padding-right: 0;
  position: relative;
}

.ui-kit-form .input-radio input,
.ui-kit-form .input-radio span::before {
  position: absolute;
}

.ui-kit-form .input-radio input {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  padding: 0;
  top: auto;
  width: 1px;
}

.ui-kit-form .input-radio span::before {
  background: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: 50%;
  box-shadow: 0 0 0 4px $color-white inset;
  box-sizing: content-box;
  content: '';
  height: 18px;
  left: 0;
  top: 1px;
  transition: border .3s ease-in-out, background .3s ease-in-out;
  width: 18px;
}

.ui-kit-form .input-radio input:checked + span::before {
  background: $color-accent;
  border-color: $color-accent;
}


// ui-kit-form:input-radio:focus
.ui-kit-form .input-radio input:focus + span::before {
  border-color: $color-accent;
}
