// _card-basic.scss

// ui-kit-card-basic
// ui-kit-card-basic:card-basic-header
// ui-kit-card-basic:card-basic-icon
// screen-large
// screen-large:ui-kit-card-basic


// ui-kit-card-basic
.ui-kit-card-basic {
  transition: box-shadow .3s ease-in-out;
}

.ui-kit-card-basic h4 {
  color: $color-primary;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  transition: color .3s ease-in-out;
}

.ui-kit-card-basic a {
  display: block;
  height: 100%;
}

.ui-kit-card-basic svg * {
  fill: $color-grey;
  transition: fill .3s ease-in-out;
}


// ui-kit-card-basic:card-basic-header
.ui-kit-card-basic .card-basic-header {
  padding: $spacing-medium;
}


// ui-kit-card-basic:card-basic-icon
.ui-kit-card-basic .card-basic-icon {
  position: absolute;
  right: $spacing-small;
  top: 50%;
  transform: translate(0, -50%);
}

.ui-kit-card-basic .card-basic-icon ~ .card-basic-header {
  padding-right: ($spacing-medium + $size-icon-large + $spacing-smaller);
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-card-basic
  .ui-kit-card-basic:hover {
    box-shadow: $box-shadow-hover;
  }

  .ui-kit-card-basic a:hover h4 {
    color: $color-secondary;
  }

  .ui-kit-card-basic a:hover svg * {
    fill: $color-secondary;
  }

}
