// _tooltip-middle.scss

// ui-kit-tooltip-middle


// ui-kit-tooltip-middle
.ui-kit-tooltip-middle {
  top: 50%;
  transform: translate(0, -50%);
}

.ui-kit-tooltip-middle.ui-kit-tooltip-right {
  left: calc(100% + #{$spacing-smaller});
  right: auto;
}

.ui-kit-tooltip-middle.ui-kit-tooltip-left {
  left: auto;
  right: calc(100% + #{$spacing-smaller});
}

.ui-kit-tooltip-middle.ui-kit-tooltip-center {
  display: none;
}
