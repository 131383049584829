// _button-small.scss

// ui-kit-button-small


// ui-kit-button-small
.ui-kit-button-small {
  font-size: $font-size-medium;
  height: $size-form-small;
  line-height: round($font-size-medium * $ratio-medium);
  padding: 7px $spacing-medium;
}
