// _loader-block.scss

// ui-kit-loader-block


// ui-kit-loader-block
.ui-kit-loader-block {
  background: rgba($color-white, .85);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}
