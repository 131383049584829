// _link-external.scss

// ui-kit-link-external
// screen-large
// screen-large:ui-kit-link-external


// ui-kit-link-external
.ui-kit-link-external {
  color: $color-secondary;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-medium);
}

.ui-kit-link-external:focus,
.ui-kit-link-external:active {
  color: darken($color-secondary, $percentage-hover);
}

.ui-kit-link-external:focus svg *,
.ui-kit-link-external:active svg * {
  fill: darken($color-secondary, $percentage-hover);
}

.ui-kit-link-external i {
  margin-left: ($spacing-smaller / 2);
}

.ui-kit-link-external svg * {
  fill: $color-secondary;
  transition: fill .3s ease-in-out;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-link-external
  .ui-kit-link-external:hover {
    color: lighten($color-secondary, $percentage-hover);
  }

  .ui-kit-link-external:hover svg * {
    fill: lighten($color-secondary, $percentage-hover);
  }

  .ui-kit-link-external:focus,
  .ui-kit-link-external:active {
    color: darken($color-secondary, $percentage-hover);
  }

  .ui-kit-link-external:focus svg *,
  .ui-kit-link-external:active svg * {
    fill: darken($color-secondary, $percentage-hover);
  }

}
