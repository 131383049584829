// _input-label-small.scss

// ui-kit-form:input-label-small


// ui-kit-form:input-label-small
.ui-kit-form .input-label-small,
.ui-kit-form .input-label-small label,
.ui-kit-form .input-label-small p {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-medium);
}
