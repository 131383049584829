// _calendar-future.scss

// ui-kit-calendar-future


// ui-kit-calendar-future
.ui-kit-calendar-future .ui-kit-calendar-month {
  background: $color-accent;
}

.ui-kit-calendar-future .ui-kit-calendar-day {
  color: $color-accent;
}
