// _form-error.scss

// ui-kit-form:form-error


// ui-kit-form:form-error
.ui-kit-form .form-error {
  background: $color-notifications-warning;
  border-radius: $border-radius-medium;
  padding: $spacing-medium $spacing-large $spacing-medium $spacing-medium;
}

.ui-kit-form .form-error p {
  color: $color-primary;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}
