// _form-item-error.scss

// ui-kit-form:form-item-error


// ui-kit-form:form-item-error
.ui-kit-form .form-item-error input,
.ui-kit-form .form-item-error select,
.ui-kit-form .form-item-error textarea {
  border-color: $color-status-error;
}
