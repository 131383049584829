// _input-search-icon.scss

// ui-kit-form:input-search-icon
// screen-large
// screen-large:ui-kit-form:input-search-icon


// ui-kit-form:input-search-icon
.ui-kit-form .input-search-icon input {
  padding-right: ($size-boundingbox-larger + ($spacing-smaller / 2) + $spacing-smaller);
}

.ui-kit-form .input-search-icon button {
  height: $size-boundingbox-larger;
  position: absolute;
  right: ($spacing-small - 13px);
  top: 50%;
  transform: translate(0, -50%);
  width: $size-boundingbox-larger;
}

.ui-kit-form .input-search-icon i {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ui-kit-form .input-search-icon svg * {
  fill: $color-secondary;
  transition: fill .3s ease-in-out;
}

.ui-kit-form .input-search-icon button:focus svg *,
.ui-kit-form .input-search-icon button:active svg * {
  fill: darken($color-secondary, $percentage-hover);
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-form:input-search-icon
  .ui-kit-form .input-search-icon input {
    padding-right: ($size-boundingbox-medium + $spacing-small + $spacing-smaller);
  }

  .ui-kit-form .input-search-icon button {
    height: $size-boundingbox-medium;
    right: ($spacing-small - 3px);
    width: $size-boundingbox-medium;
  }

  .ui-kit-form .input-search-icon button:hover svg * {
    fill: lighten($color-secondary, $percentage-hover);
  }

  .ui-kit-form .input-search-icon button:focus svg *,
  .ui-kit-form .input-search-icon button:active svg * {
    fill: darken($color-secondary, $percentage-hover);
  }

}
