// _icon-inline.scss

// ui-kit-icon-inline


// ui-kit-icon-inline
.ui-kit-icon-inline {
  display: inline-block;
  margin-top: -3px;
  vertical-align: middle;
}
