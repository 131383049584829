// _icon-small.scss

// ui-kit-icon-small


// ui-kit-icon-small
.ui-kit-icon-small,
.ui-kit-icon-small svg {
  height: $size-icon-small;
  width: $size-icon-small;
}
