// _form-header.scss

// ui-kit-form:form-header


// ui-kit-form:form-header
.ui-kit-form .form-header h3 {
  font-size: $font-size-large;
  font-weight: 900;
  line-height: round($font-size-large * $ratio-medium);
}
