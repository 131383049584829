// _avatar-large.scss

// ui-kit-avatar-large


// ui-kit-avatar-large
.ui-kit-avatar-large {
  font-size: $font-size-large;
  height: $size-avatar-large;
  line-height: round($font-size-large * $ratio-small);
  width: $size-avatar-large;
}
