// _button-outline.scss

// ui-kit-button-outline
// ui-kit-button-outline:animation
// screen-large
// screen-large:ui-kit-button-outline


// ui-kit-button-outline
.ui-kit-button-outline {
  background: transparent;
  color: $color-secondary;
  transition: color .3s ease-in-out, background .3s ease-in-out;
}

.ui-kit-button-outline::after {
  border: 1px solid $color-secondary;
  border-radius: $border-radius-large;
  box-sizing: content-box;
  content: '';
  height: calc(100% - 2px);
  left: 0;
  position: absolute;
  top: 0;
  transition: border .3s ease-in-out;
  width: calc(100% - 2px);
}

.ui-kit-button-outline > em,
.ui-kit-button-outline > em::before,
.ui-kit-button-outline > em::after {
  animation-name: ui-kit-button-outline;
  background: rgba($color-secondary, .25);
}

.ui-kit-button-outline svg * {
  fill: $color-secondary;
  transition: fill .3s ease-in-out;
}

.ui-kit-button-outline:focus,
.ui-kit-button-outline:active {
  background: darken($color-secondary, $percentage-hover);
  color: $color-white;
}

.ui-kit-button-outline:focus::after,
.ui-kit-button-outline:active::after {
  border-color: darken($color-secondary, $percentage-hover);
}

.ui-kit-button-outline:focus svg *,
.ui-kit-button-outline:active svg * {
  fill: $color-white;
}


// ui-kit-button-outline:animation
@keyframes ui-kit-button-outline {
  0% { background: rgba($color-secondary, .25); }
  50% { background: rgba($color-secondary, 1); }
  90% { background: rgba($color-secondary, .25); }
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-button-outline
  .ui-kit-button-outline:hover {
    background: lighten($color-secondary, $percentage-hover);
    color: $color-white;
  }

  .ui-kit-button-outline:hover::after {
    border-color: lighten($color-secondary, $percentage-hover);
  }

  .ui-kit-button-outline:focus,
  .ui-kit-button-outline:active {
    background: darken($color-secondary, $percentage-hover);
    color: $color-white;
  }

  .ui-kit-button-outline:focus::after,
  .ui-kit-button-outline:active::after {
    border-color: darken($color-secondary, $percentage-hover);
  }

  .ui-kit-button-outline:hover svg * {
    fill: $color-white;
  }

}
