// _input-search.scss

// ui-kit-form:input-search
// ui-kit-form:input-search:placeholder


// ui-kit-form:input-search
.ui-kit-form .input-search {
  position: relative;
}

.ui-kit-form .input-search input {
  border-radius: $border-radius-large;
}

.ui-kit-form .input-search button {
  background: transparent;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-medium);
  margin: 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  width: auto;
}

.ui-kit-form .input-search button:focus,
.ui-kit-form .input-search button:active {
  outline: 0;
}


// ui-kit-form:input-search:placeholder
.ui-kit-form .form-item .hidden ~ .input-search ::placeholder {
  color: $color-grey;
}
