// _card-three.scss

// ui-kit-card-three
// screen-medium
// screen-medium:ui-kit-card-three
// screen-large
// screen-large:ui-kit-card-three


// ui-kit-card-three
.ui-kit-card-three {
  width: calc(100% - #{$spacing-small});
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-card-three
  .ui-kit-card-three {
    width: calc(50% - #{$spacing-small});
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-card-three
  .ui-kit-card-three {
    width: calc(33.33% - #{$spacing-large});
  }

}
