// _link-icon-larger.scss

// ui-kit-link-icon-larger


// ui-kit-link-icon-larger
.ui-kit-link-icon-larger svg {
  height: $size-icon-larger;
  width: $size-icon-larger;
}
