// _card.scss

// ui-kit-card
// screen-medium
// screen-medium:ui-kit-card
// screen-large
// screen-large:ui-kit-card


// ui-kit-card
.ui-kit-card {
  background: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-normal;
  box-sizing: border-box;
  margin: ($spacing-small / 2);
  position: relative;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-card
  .ui-kit-card {
    margin: ($spacing-small / 2);
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-card
  .ui-kit-card {
    margin: ($spacing-large / 2);
  }

}
