// _snackbar.scss

// ui-kit-snackbar
// ui-kit-snackbar-close
// screen-medium
// screen-medium:ui-kit-snackbar
// screen-large
// screen-large:ui-kit-snackbar-close


// ui-kit-snackbar
.ui-kit-snackbar {
  background: $color-primary;
  border-radius: $border-radius-small;
  box-sizing: border-box;
  left: 50%;
  padding: $spacing-small $spacing-medium;
  position: fixed;
  transform: translate(-50%, 0);
  width: calc(100% - #{$spacing-small} * 2);
  z-index: 5;
}

.ui-kit-snackbar p,
.ui-kit-snackbar a {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}

.ui-kit-snackbar p {
  color: $color-white;
}


// ui-kit-snackbar-close
.ui-kit-snackbar-close {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(0, -50%);
}

.ui-kit-snackbar-close ~ div {
  padding-right: $spacing-medium;
}

.ui-kit-snackbar-close a svg * {
  fill: $color-grey-lighter;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-snackbar
  .ui-kit-snackbar {
    max-width: ($size-medium * 2);
    width: auto;
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-snackbar-close
  .ui-kit-snackbar-close {
    right: $spacing-small;
  }

  .ui-kit-snackbar-close a:hover svg * {
    fill: $color-white;
  }

}
