// _loader.scss

// ui-kit-loader
// ui-kit-loader:animation


// ui-kit-loader
.ui-kit-loader {
  min-height: $spacing-larger;
  position: relative;
  width: 100%;
}

.ui-kit-loader > em,
.ui-kit-loader > em::before,
.ui-kit-loader > em::after {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: ui-kit-loader;
  animation-timing-function: ease-in;
  background: rgba($color-secondary, .25);
  border-radius: 50%;
  position: absolute;
}

.ui-kit-loader > em {
  animation-delay: .5s;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ui-kit-loader > em::before,
.ui-kit-loader > em::after {
  content: '';
  top: 0;
}

.ui-kit-loader > em::before {
  animation-delay: 0s;
}

.ui-kit-loader > em::after {
  animation-delay: 1s;
}


// ui-kit-loader:animation
@keyframes ui-kit-loader {
  0% { background: rgba($color-secondary, .25); }
  50% { background: rgba($color-secondary, 1); }
  90% { background: rgba($color-secondary, .25); }
}
