// _accessibility-span.scss

// ui-kit-accessibility-span


// ui-kit-accessibility-span
.ui-kit-accessibility-span {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}
