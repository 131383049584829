// _calendar.scss

// ui-kit-calendar
// ui-kit-calendar-month
// ui-kit-calendar-day


// ui-kit-calendar
.ui-kit-calendar {
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-normal;
  display: block;
  font-style: normal;
  font-weight: 900;
  overflow: hidden;
  text-align: center;
  width: 80px;
}

.ui-kit-calendar span {
  display: block;
}


// ui-kit-calendar-month
.ui-kit-calendar-month {
  background: $color-primary;
  color: $color-white;
  font-size: $font-size-small;
  line-height: 24px;
  padding-top: 2px;
  text-transform: uppercase;
}


// ui-kit-calendar-day
.ui-kit-calendar-day {
  background: $color-white;
  color: $color-primary;
  font-size: $font-size-larger;
  line-height: 50px;
  min-height: 50px;
  padding-bottom: 4px;
}
