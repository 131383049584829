// _tag.scss

// ui-kit-tag


// ui-kit-tag
.ui-kit-tag {
  background: $color-grey-light;
  border-radius: $border-radius-large;
  color: $color-grey-dark;
  font-size: $font-size-small;
  font-weight: 700;
  line-height: round($font-size-small * $ratio-small);
  padding: $spacing-smaller $spacing-small;
}

.ui-kit-tag + .ui-kit-tag {
  margin-left: $spacing-smaller;
}
