// _link-icon-small.scss

// ui-kit-link-icon-small


// ui-kit-link-icon-small
.ui-kit-link-icon-small svg {
  height: $size-icon-small;
  width: $size-icon-small;
}
