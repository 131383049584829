// _link-icon.scss

// ui-kit-link-icon
// screen-large
// screen-large:ui-kit-link-icon


// ui-kit-link-icon
.ui-kit-link-icon {
  display: block;
  overflow: hidden;
  position: relative;
}

.ui-kit-link-icon i {
  display: block;
  height: $size-boundingbox-larger;
  position: relative;
  width: $size-boundingbox-larger;
}

.ui-kit-link-icon svg {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ui-kit-link-icon svg * {
  fill: $color-grey;
  transition: fill .3s ease-in-out;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-link-icon
  .ui-kit-link-icon i {
    height: $size-boundingbox-medium;
    width: $size-boundingbox-medium;
  }

  .ui-kit-link-icon:hover svg * {
    fill: $color-primary;
  }

}
