// _input-clear.scss

// ui-kit-form:input-clear


// ui-kit-form:input-clear
.ui-kit-form .input-clear {
  margin-top: $spacing-smaller;
  padding: 0 $spacing-small;
}

.ui-kit-form .input-clear p {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}
