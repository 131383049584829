// _input-text-count.scss

// ui-kit-form:input-text-count


// ui-kit-form:input-text-count
.ui-kit-form .input-text-count {
  position: relative;
}

.ui-kit-form .input-text-count input {
  padding-right: ($spacing-larger * 2);
}

.ui-kit-form .input-text-count .counter {
  color: $color-grey-dark;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-medium);
  position: absolute;
  right: $spacing-small;
  top: 50%;
  transform: translate(0, -50%);
}
