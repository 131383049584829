// _input-radio-date.scss

// ui-kit-form:input-radio-date
// ui-kit-form:input-radio-date:date-inactive
// screen-large
// screen-large:ui-kit-form:input-radio-date
// screen-large:ui-kit-form:input-radio-date:date-inactive


// ui-kit-form:input-radio-date
.ui-kit-form .input-radio-date {
  display: inline-block;
  text-align: center;
  width: 48px;
}

.ui-kit-form .input-radio-date + .input-radio-date {
  margin-top: 0;
}

.ui-kit-form .input-radio-date span {
  font-weight: 700;
}

.ui-kit-form .input-radio-date span::before {
  content: none;
}

.ui-kit-form .input-radio-date label {
  display: block;
  padding: 0;
}

.ui-kit-form .input-radio-date .date {
  background: $color-white;
  border-radius: 50%;
  box-shadow: $box-shadow-normal;
  box-sizing: border-box;
  display: block;
  font-size: $font-size-large;
  height: 48px;
  line-height: round($font-size-large * $ratio-small);
  padding: 15px 0;
  transition: background .3s ease-in-out, box-shadow .3s ease-in-out, color .3s ease-in-out;
  width: 48px;
}

.ui-kit-form .input-radio-date input:checked + .date {
  background: $color-accent;
  box-shadow: none;
  color: $color-white;
}

.ui-kit-form .input-radio-date .day {
  display: block;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-medium);
  margin-top: $spacing-smaller;
}


// ui-kit-form:input-radio-date:date-inactive
.ui-kit-form .input-radio-date .date-inactive .date {
  background: $color-grey-light;
  box-shadow: none;
  color: $color-grey;
  position: relative;
}

.ui-kit-form .input-radio-date .date-inactive .day {
  color: $color-grey;
}

.ui-kit-form .input-radio-date .date-inactive input:checked + .date {
  background: $color-accent;
  border-color: $color-accent;
  color: $color-white;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-form:input-radio-date
  .ui-kit-form .input-radio-date label:hover .date {
    background: $color-accent;
    border-color: $color-accent;
    color: $color-white;
  }

  .ui-kit-form .input-radio-date label:hover input:checked + .date {
    box-shadow: none;
  }


  // screen-large:ui-kit-form:input-radio-date:date-inactive
  .ui-kit-form .input-radio-date .date-inactive:hover .date {
    box-shadow: none;
  }

}
