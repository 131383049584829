// _button-disabled.scss

// ui-kit-button-disabled


// ui-kit-button-disabled
.ui-kit-button-disabled {
  opacity: .5;
  pointer-events: none;
}
