// _avatar-small.scss

// ui-kit-avatar-small


// ui-kit-avatar-small
.ui-kit-avatar-small {
  font-size: $font-size-small;
  height: $size-avatar-small;
  line-height: round($font-size-small * $ratio-small);
  width: $size-avatar-small;
}
