// _link-icon-large.scss

// ui-kit-link-icon-large


// ui-kit-link-icon-large
.ui-kit-link-icon-large svg {
  height: $size-icon-large;
  width: $size-icon-large;
}
