// _overlay-triage.scss

// ui-kit-overlay-triage:overlay-triage-content
// ui-kit-overlay-triage:overlay-triage-buttons


// ui-kit-overlay-triage:overlay-triage-content
// ui-kit-overlay-triage:overlay-triage-buttons
.ui-kit-overlay-triage .overlay-triage-buttons {
  font-size: 0;
  line-height: 0;
  margin-top: $spacing-larger;
}
