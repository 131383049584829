// _form-item.scss

// ui-kit-form:form-item


// ui-kit-form:form-item
.ui-kit-form .form-item + .form-item {
  margin-top: $spacing-medium;
}

.ui-kit-form .form-item .label {
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-medium);
  margin-bottom: $spacing-small;
}

.ui-kit-form .form-item .hidden {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.ui-kit-form .form-item .hidden ~ div ::placeholder {
  color: $color-grey-dark;
}
