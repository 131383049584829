// _input-password-toggle.scss

// ui-kit-form:input-password-toggle


// ui-kit-form:input-password-toggle
.ui-kit-form .input-password-toggle {
  position: relative;
}

.ui-kit-form .input-password-toggle input {
  padding-right: ($spacing-larger + ($spacing-small * 2));
}

.ui-kit-form .input-password-toggle .toggle-text {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  position: absolute;
  right: $spacing-small;
  top: 50%;
  transform: translate(0, -50%);
}

.ui-kit-form .input-password-toggle .toggle-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.ui-kit-form .input-password-toggle .toggle-icon a {
  display: block;
  overflow: hidden;
  position: relative;
}

.ui-kit-form .input-password-toggle .toggle-icon i {
  display: block;
  height: $size-boundingbox-larger;
  position: relative;
  width: $size-boundingbox-larger;
}

.ui-kit-form .input-password-toggle .toggle-icon svg {
  height: $size-icon-large;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: $size-icon-large;
}

.ui-kit-form .input-password-toggle .toggle-icon .icon-outline * {
  fill: $color-grey-dark;
}

.ui-kit-form .input-password-toggle .toggle-icon .icon-fill * {
  fill: $color-accent;
}

.ui-kit-form .input-password-toggle input[type='password'] ~ .toggle-icon svg:nth-child(1), // sass-lint:disable-line no-qualifying-elements
.ui-kit-form .input-password-toggle input[type='text'] ~ .toggle-icon svg:nth-child(2) { // sass-lint:disable-line no-qualifying-elements
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.ui-kit-form .input-password-toggle input[type='text'] ~ .toggle-icon svg:nth-child(1), // sass-lint:disable-line no-qualifying-elements
.ui-kit-form .input-password-toggle input[type='password'] ~ .toggle-icon svg:nth-child(2) { // sass-lint:disable-line no-qualifying-elements
  opacity: 0;
  transition: opacity .3s ease-in-out;
}
