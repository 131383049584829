// _toggle-text.scss

// ui-kit-toggle-text
// ui-kit-toggle-text-checked
// screen-large
// screen-large:ui-kit-toggle-text


// ui-kit-toggle-text
.ui-kit-toggle-text {
  display: inline-flex;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-small);
}

.ui-kit-toggle-text span,
.ui-kit-toggle-text a {
  border-radius: $border-radius-large;
  padding-bottom: 11px;
  padding-top: 11px;
}

.ui-kit-toggle-text span {
  background: $color-grey-light;
  box-shadow: $box-shadow-normal;
  color: $color-primary;
  padding-left: $spacing-small;
  padding-right: $spacing-small;
  z-index: 1;
}

.ui-kit-toggle-text a {
  background: $color-white;
  color: $color-grey-dark;
  margin-left: -($spacing-larger);
  padding-left: ($spacing-medium + $spacing-larger);
  padding-right: $spacing-large;
  position: relative;
  transition: background .3s ease-in-out, color .3s ease-in-out;
}

.ui-kit-toggle-text a:focus,
.ui-kit-toggle-text a:active {
  background: $color-grey-lighter;
  color: $color-primary;
}

.ui-kit-toggle-text a::after {
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-large;
  content: '';
  height: calc(100% - 2px);
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 2px);
}


// ui-kit-toggle-text-checked
.ui-kit-toggle-text-checked span {
  background: $color-status-success;
  color: $color-white;
  padding-left: $spacing-large;
  padding-right: $spacing-large;
}

.ui-kit-toggle-text-checked a {
  margin-left: 0;
  margin-right: -($spacing-larger);
  padding-left: $spacing-small;
  padding-right: ($spacing-smaller + $spacing-larger);
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-toggle-text
  .ui-kit-toggle-text a:hover {
    background: $color-grey-lighter;
    color: $color-primary;
  }

}
