// _form.scss

// ui-kit-form
// ui-kit-form:placeholder
// ui-kit-form:browser-specific-resets


// ui-kit-form
.ui-kit-form,
.ui-kit-form fieldset,
.ui-kit-form legend,
.ui-kit-form label,
.ui-kit-form input,
.ui-kit-form select,
.ui-kit-form textarea {
  border: 0;
  color: $color-primary;
  display: block;
  margin: 0;
  padding: 0;
}

.ui-kit-form legend,
.ui-kit-form label,
.ui-kit-form input,
.ui-kit-form select,
.ui-kit-form textarea {
  font-weight: 700;
}

.ui-kit-form legend {
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-small);
  width: 100%;
}

.ui-kit-form label {
  display: inline-block;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  margin-bottom: $spacing-smaller;
  padding: 0 $spacing-small;
}

.ui-kit-form input,
.ui-kit-form input[type], // sass-lint:disable-line no-qualifying-elements
.ui-kit-form select,
.ui-kit-form textarea {
  appearance: none;
  background: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-small;
  box-sizing: border-box;
  color: $color-primary;
  font-family: inherit;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  padding: 9px ($spacing-small - 1px);
  transition: border .3s ease-in-out;
  width: 100%;
}

.ui-kit-form input,
.ui-kit-form input[type], // sass-lint:disable-line no-qualifying-elements
.ui-kit-form select {
  height: $size-form-medium;
}

.ui-kit-form button {
  font-family: inherit;
}

.ui-kit-form input:not([readonly]):focus,
.ui-kit-form input:not([readonly]):active,
.ui-kit-form select:not([readonly]):focus,
.ui-kit-form select:not([readonly]):active,
.ui-kit-form textarea:not([readonly]):focus,
.ui-kit-form textarea:not([readonly]):active {
  border-color: $color-accent;
  outline: 0;
}

.ui-kit-form textarea {
  resize: none;
}


// ui-kit-form:placeholder
.ui-kit-form ::placeholder {
  color: $color-grey;
}


// ui-kit-form:browser-specific-resets
// sass-lint:disable-block no-vendor-prefixes
.ui-kit-form :-ms-input-placeholder {
  color: $color-grey !important; // sass-lint:disable-line no-important
}

.ui-kit-form ::-moz-placeholder {
  opacity: 1;
}

.ui-kit-form ::-ms-clear,
.ui-kit-form ::-ms-reveal,
.ui-kit-form ::-ms-expand {
  display: none;
}

.ui-kit-form ::-webkit-search-cancel-button {
  display: none;
}

.ui-kit-form ::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}
