// _card-empty.scss

// ui-kit-card-empty
// ui-kit-card-empty:card-empty-content
// ui-kit-card-empty:card-empty-notification
// ui-kit-card-empty:card-empty-buttons


// ui-kit-card-empty
.ui-kit-card-empty p {
  color: $color-grey-dark;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}


// ui-kit-card-empty:card-empty-content
.ui-kit-card-empty .card-empty-content {
  padding: $spacing-larger $spacing-medium;
  text-align: center;
}


// ui-kit-card-empty:card-empty-notification
.ui-kit-card-empty .card-empty-notification i,
.ui-kit-card-empty .card-empty-notification svg {
  height: ($size-icon-larger * 2);
  width: ($size-icon-larger * 2);
}

.ui-kit-card-empty .card-empty-notification i {
  margin: 0 auto $spacing-small;
}

.ui-kit-card-empty .card-empty-notification svg * {
  fill: $color-accent;
}


// ui-kit-card-empty:card-empty-buttons
.ui-kit-card-empty .card-empty-buttons {
  margin-top: $spacing-medium;
}
