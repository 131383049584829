// _overlay-confirmation.scss

// ui-kit-overlay-confirmation
// ui-kit-overlay-confirmation:overlay-confirmation-header
// ui-kit-overlay-confirmation:overlay-confirmation-content
// ui-kit-overlay-confirmation:overlay-confirmation-buttons
// screen-medium
// screen-medium:ui-kit-overlay-confirmation:overlay-confirmation-header
// screen-medium:ui-kit-overlay-confirmation:overlay-confirmation-content


// ui-kit-overlay-confirmation
.ui-kit-overlay-confirmation h4 {
  color: $color-grey-dark;
  font-size: $font-size-large;
  font-weight: 400;
  line-height: round($font-size-large * $ratio-medium);
}


// ui-kit-overlay-confirmation:overlay-confirmation-header
.ui-kit-overlay-confirmation .overlay-confirmation-header {
  text-align: center;
}


// ui-kit-overlay-confirmation:overlay-confirmation-content
.ui-kit-overlay-confirmation .overlay-confirmation-content {
  margin-top: $spacing-medium;
  text-align: center;
}


// ui-kit-overlay-confirmation:overlay-confirmation-buttons
.ui-kit-overlay-confirmation .overlay-confirmation-buttons {
  font-size: 0;
  line-height: 0;
  margin-top: $spacing-larger;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-overlay-confirmation:overlay-confirmation-header
  .ui-kit-overlay-confirmation .overlay-confirmation-header {
    text-align: left;
  }


  // screen-medium:ui-kit-overlay-confirmation:overlay-confirmation-content
  .ui-kit-overlay-confirmation .overlay-confirmation-content {
    text-align: left;
  }

}
