// _skeleton-avatarlist.scss

// ui-kit-skeleton-avatarlist


// ui-kit-skeleton-avatarlist
.ui-kit-skeleton-avatarlist {
  align-items: center;
  display: flex;
}

.ui-kit-skeleton-avatarlist > div:nth-child(1) {
  flex-shrink: 0;
  margin-right: $spacing-small;
}

.ui-kit-skeleton-avatarlist > div:nth-child(2) {
  flex: 1;
}
