// _input-search-button.scss

// ui-kit-form:input-search-button
// screen-large
// screen-large:ui-kit-form:input-search-button


// ui-kit-form:input-search-button
.ui-kit-form .input-search-button {
  display: flex;
}

.ui-kit-form .input-search-button input {
  flex: 1;
  margin-right: -($spacing-larger);
  padding-left: ($size-icon-large + $spacing-small + $spacing-smaller);
  padding-right: ($spacing-larger + $spacing-smaller);
}

.ui-kit-form .input-search-button button {
  background: $color-secondary;
  border-radius: $border-radius-large;
  color: $color-white;
  flex-shrink: 0;
  padding: 10px $spacing-large;
  transition: background .3s ease-in-out;
}

.ui-kit-form .input-search-button button:focus,
.ui-kit-form .input-search-button button:active {
  background: darken($color-secondary, $percentage-hover);
  color: $color-white;
}

.ui-kit-form .input-search-button i {
  left: $spacing-small;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ui-kit-form .input-search-button svg * {
  fill: $color-grey;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-form:input-search-button
  .ui-kit-form .input-search-button button:hover {
    background: lighten($color-secondary, $percentage-hover);
    color: $color-white;
  }

  .ui-kit-form .input-search-button button:focus,
  .ui-kit-form .input-search-button button:active {
    background: darken($color-secondary, $percentage-hover);
    color: $color-white;
  }

}
