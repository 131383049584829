// _snackbar-top.scss

// ui-kit-snackbar-top
// ui-kit-snackbar-top:ui-kit-snackbar-visible


// ui-kit-snackbar-top
.ui-kit-snackbar-top {
  top: -($size-medium / 2);
  transition: top .5s ease-in-out;
}


// ui-kit-snackbar-top:ui-kit-snackbar-visible
.ui-kit-snackbar-top.ui-kit-snackbar-visible {
  top: $spacing-small;
}
