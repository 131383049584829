// _input-error.scss

// ui-kit-form:input-error


// ui-kit-form:input-error
.ui-kit-form .input-error {
  margin-top: $spacing-smaller;
  padding: 0 $spacing-small;
}

.ui-kit-form .input-error p {
  color: $color-status-error;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-medium);
}
