// _card-two.scss

// ui-kit-card-two
// screen-medium
// screen-medium:ui-kit-card-two
// screen-large
// screen-large:ui-kit-card-two


// ui-kit-card-two
.ui-kit-card-two {
  width: calc(100% - #{$spacing-small});
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-card-two
  .ui-kit-card-two {
    width: calc(50% - #{$spacing-small});
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-card-two
  .ui-kit-card-two {
    width: calc(50% - #{$spacing-large});
  }

}
