// _button-secondary.scss

// ui-kit-button-secondary
// ui-kit-button-secondary:animation
// screen-large
// screen-large:ui-kit-button-secondary


// ui-kit-button-secondary
.ui-kit-button-secondary {
  background: $color-accent;
  color: $color-white;
  transition: background .3s ease-in-out;
}

.ui-kit-button-secondary > em,
.ui-kit-button-secondary > em::before,
.ui-kit-button-secondary > em::after {
  animation-name: ui-kit-button-secondary;
  background: rgba($color-white, .25);
}

.ui-kit-button-secondary svg * {
  fill: $color-white;
}

.ui-kit-button-secondary:focus,
.ui-kit-button-secondary:active {
  background: darken($color-accent, $percentage-hover);
  color: $color-white;
}


// ui-kit-button-secondary:animation
@keyframes ui-kit-button-secondary {
  0% { background: rgba($color-white, .25); }
  50% { background: rgba($color-white, 1); }
  90% { background: rgba($color-white, .25); }
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-button-secondary
  .ui-kit-button-secondary:hover {
    background: lighten($color-accent, $percentage-hover);
    color: $color-white;
  }

  .ui-kit-button-secondary:focus,
  .ui-kit-button-secondary:active {
    background: darken($color-accent, $percentage-hover);
    color: $color-white;
  }

}
