// _avatar.scss

// ui-kit-avatar


// ui-kit-avatar
.ui-kit-avatar {
  background: $color-primary;
  border-radius: 50%;
  color: $color-white;
  display: block;
  font-style: normal;
  font-weight: 900;
  position: relative;
}

.ui-kit-avatar span {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
