// _status-success.scss

// ui-kit-status-success


// ui-kit-status-success
.ui-kit-status-success {
  background: $color-status-success;
}

.ui-kit-status-success p {
  color: $color-white;
}
