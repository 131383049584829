// _input-label.scss

// ui-kit-form:input-label


// ui-kit-form:input-label
.ui-kit-form .input-label {
  margin-bottom: $spacing-smaller;
}

.ui-kit-form .input-label label {
  margin-bottom: 0;
}

.ui-kit-form .input-label p {
  font-weight: 700;
}
