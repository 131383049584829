// _button-medium.scss

// ui-kit-button-medium


// ui-kit-button-medium
.ui-kit-button-medium {
  font-size: $font-size-medium;
  height: $size-form-medium;
  line-height: round($font-size-medium * $ratio-medium);
  padding: 10px $spacing-large;
}
