// _button-loading.scss

// ui-kit-button-loading


// ui-kit-button-loading
.ui-kit-button-loading {
  pointer-events: none;
}

.ui-kit-button-loading > em,
.ui-kit-button-loading > em::before,
.ui-kit-button-loading > em::after {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  border-radius: 50%;
  height: 8px;
  position: absolute;
  width: 8px;
}

.ui-kit-button-loading > em {
  animation-delay: .5s;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ui-kit-button-loading > em::before,
.ui-kit-button-loading > em::after {
  content: '';
  top: 0;
}

.ui-kit-button-loading > em::before {
  animation-delay: 0s;
  left: -16px;
}

.ui-kit-button-loading > em::after {
  animation-delay: 1s;
  left: 16px;
}

.ui-kit-button-loading > span,
.ui-kit-button-loading > i {
  visibility: hidden;
}
