// _button.scss

// ui-kit-button
// screen-medium
// screen-medium:ui-kit-button


// ui-kit-button
.ui-kit-button {
  border: 0;
  border-radius: $border-radius-large;
  box-sizing: border-box;
  color: $color-primary;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: 700;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.ui-kit-button > em {
  display: none;
}

.ui-kit-button > span {
  visibility: visible;
}

.ui-kit-button > i + span,
.ui-kit-button > span + i {
  margin-left: $spacing-smaller;
}

.ui-kit-button:focus,
.ui-kit-button:active {
  outline: 0;
}

.ui-kit-button + .ui-kit-button {
  margin-top: $spacing-small;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-button
  .ui-kit-button {
    width: auto;
  }

  .ui-kit-button + .ui-kit-button {
    margin-left: $spacing-medium;
    margin-top: 0;
  }

}
