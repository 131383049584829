// _snackbar-block.scss

// ui-kit-snackbar-block:snackbar-actions


// ui-kit-snackbar-block:snackbar-actions
.ui-kit-snackbar-block .snackbar-actions {
  margin-top: $spacing-small;
}

.ui-kit-snackbar-block .snackbar-actions a + a {
  margin-left: $spacing-small;
}
