// _no-results-info.scss

// ui-kit-no-results-info


// ui-kit-no-results-info
.ui-kit-no-results-info h2 {
  color: $color-primary;
}

.ui-kit-no-results-info p {
  color: $color-grey-dark;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  margin-top: $spacing-smaller;
}
