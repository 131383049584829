// _input-checkbox-card.scss

// ui-kit-form:input-checkbox-card
// screen-large
// screen-large:ui-kit-form:input-checkbox-card


// ui-kit-form:input-checkbox-card
.ui-kit-form .input-checkbox-card {
  max-width: $size-medium;
}

.ui-kit-form .input-checkbox-card + .input-checkbox-card {
  margin-top: $spacing-medium;
}

.ui-kit-form .input-checkbox-card label {
  background: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-normal;
  display: block;
  padding-bottom: $spacing-medium;
  padding-left: (($spacing-small + 22px) + $spacing-medium);
  padding-right: $spacing-medium;
  padding-top: $spacing-medium;
  transition: box-shadow .3s ease-in-out;
}

.ui-kit-form .input-checkbox-card span {
  font-weight: 700;
}

.ui-kit-form .input-checkbox-card span::before {
  left: $spacing-medium;
  top: $spacing-medium + 1px;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-form:input-checkbox-card
  .ui-kit-form .input-checkbox-card label:hover {
    box-shadow: $box-shadow-hover;
  }

}
