// _skeleton-selector.scss

// ui-kit-skeleton-selector


// ui-kit-skeleton-selector
.ui-kit-skeleton-selector {
  align-items: center;
  display: flex;
}

.ui-kit-skeleton-selector > div:nth-child(1) {
  flex-shrink: 0;
  margin-right: $spacing-small;
}
