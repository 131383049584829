// _tooltip-center.scss

// ui-kit-tooltip-center


// ui-kit-tooltip-center
.ui-kit-tooltip-center {
  left: 50%;
  transform: translate(-50%, 0);
}
