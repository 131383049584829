// _status-error.scss

// ui-kit-status-error


// ui-kit-status-error
.ui-kit-status-error {
  background: $color-status-error;
}

.ui-kit-status-error p {
  color: $color-white;
}
