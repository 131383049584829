// _pagination.scss

// ui-kit-pagination
// ui-kit-pagination-button
// ui-kit-pagination-active
// ui-kit-pagination-disabled
// screen-large
// screen-large:ui-kit-pagination
// screen-large:ui-kit-pagination-button


// ui-kit-pagination
.ui-kit-pagination {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  margin-top: $spacing-large;
}

.ui-kit-pagination ul,
.ui-kit-pagination li {
  margin: 0;
  padding: 0;
}

.ui-kit-pagination ul {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.ui-kit-pagination li {
  list-style-type: none;
}

.ui-kit-pagination li:not(.ui-kit-pagination-active):not(.ui-kit-pagination-button) {
  display: none;
}

.ui-kit-pagination a {
  background: transparent;
  border-radius: $border-radius-large;
  color: $color-primary;
  cursor: pointer;
  display: block;
  font-weight: 700;
  height: ($size-icon-larger * 2);
  line-height: ($size-icon-larger * 2);
  transition: background .3s ease-in-out, color .3s ease-in-out;
  width: ($size-icon-larger * 2);
}

.ui-kit-pagination a:focus {
  outline: 0;
}


// ui-kit-pagination-button
.ui-kit-pagination-button {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.ui-kit-pagination-button a {
  background: $color-white;
  border: 1px solid $color-grey-light;
  height: (($size-icon-larger * 2) - 2px);
  position: relative;
  text-indent: -9000px;
  transition: border .3s ease-in-out, background .3s ease-in-out;
  width: (($size-icon-larger * 2) - 2px);
}

.ui-kit-pagination-button a::before,
.ui-kit-pagination-button a::after {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: $size-icon-small $size-icon-small;
  content: '';
  height: $size-icon-small;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .3s ease-in-out;
  width: $size-icon-small;
}

.ui-kit-pagination-button a::before {
  opacity: 1;
}

.ui-kit-pagination-button a::after {
  opacity: 0;
}

.ui-kit-pagination-button:first-of-type {
  margin-right: ($spacing-medium * 2);
}

.ui-kit-pagination-button:first-of-type a::before,
.ui-kit-pagination-button:first-of-type a::after {
  margin-left: -1px;
}

.ui-kit-pagination-button:first-of-type a::before {
  background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbi1jaGV2cm9uLWxlZnQgaWNvbi1vdXRsaW5lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMzIzMjVkIiBkPSJNMTguNDEsMy43MSwxMC4xLDEybDguMzEsOC4yOS4xLjFhMi4xMywyLjEzLDAsMCwxLS4wOCwzQTIuMTYsMi4xNiwwLDAsMSwxNi45LDI0YTIuMDgsMi4wOCwwLDAsMS0xLjQ5LS41OWwtLjA4LS4wOEw1LjU2LDEzLjU2bC0uMDgtLjA3YTIuMjIsMi4yMiwwLDAsMS0uNTItMiwyLjE0LDIuMTQsMCwwLDEsLjUyLTFsLjA3LS4wN0wxNS4zNS42N2EuMy4zLDAsMCwxLC4wOC0uMDgsMi4xNCwyLjE0LDAsMCwxLDMsM1oiPjwvcGF0aD48L3N2Zz4K');
}

.ui-kit-pagination-button:first-of-type a::after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbi1jaGV2cm9uLWxlZnQgaWNvbi1vdXRsaW5lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTguNDEsMy43MSwxMC4xLDEybDguMzEsOC4yOS4xLjFhMi4xMywyLjEzLDAsMCwxLS4wOCwzQTIuMTYsMi4xNiwwLDAsMSwxNi45LDI0YTIuMDgsMi4wOCwwLDAsMS0xLjQ5LS41OWwtLjA4LS4wOEw1LjU2LDEzLjU2bC0uMDgtLjA3YTIuMjIsMi4yMiwwLDAsMS0uNTItMiwyLjE0LDIuMTQsMCwwLDEsLjUyLTFsLjA3LS4wN0wxNS4zNS42N2EuMy4zLDAsMCwxLC4wOC0uMDgsMi4xNCwyLjE0LDAsMCwxLDMsM1oiPjwvcGF0aD48L3N2Zz4K');
}

.ui-kit-pagination-button:last-of-type {
  margin-left: ($spacing-medium * 2);
}

.ui-kit-pagination-button:last-of-type a::before,
.ui-kit-pagination-button:last-of-type a::after {
  margin-left: 1px;
}

.ui-kit-pagination-button:last-of-type a::before {
  background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbi1jaGV2cm9uLXJpZ2h0IGljb24tb3V0bGluZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzMyMzI1ZCIgZD0iTTYsMjAuMjksMTQuMzEsMTIsNiwzLjcxYS40Ny40NywwLDAsMS0uMS0uMUEyLjE2LDIuMTYsMCwwLDEsNiwuNTgsMi4yMSwyLjIxLDAsMCwxLDcuNTEsMCwyLjA3LDIuMDcsMCwwLDEsOSwuNTlsLjA4LjA4LDkuNzcsOS43Ny4wOC4wN2EyLjE5LDIuMTksMCwwLDEsLjUyLDIsMi4xNCwyLjE0LDAsMCwxLS41MiwxbC0uMDcuMDctOS44LDkuNzVhLjMuMywwLDAsMS0uMDguMDhBMi4xMSwyLjExLDAsMCwxLDcuNTEsMjRhMi4xMywyLjEzLDAsMCwxLTIuMTUtMi4xMywyLjA5LDIuMDksMCwwLDEsLjU4LTEuNDhaIj48L3BhdGg+PC9zdmc+Cg==');
}

.ui-kit-pagination-button:last-of-type a::after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbi1jaGV2cm9uLXJpZ2h0IGljb24tb3V0bGluZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTYsMjAuMjksMTQuMzEsMTIsNiwzLjcxYS40Ny40NywwLDAsMS0uMS0uMUEyLjE2LDIuMTYsMCwwLDEsNiwuNTgsMi4yMSwyLjIxLDAsMCwxLDcuNTEsMCwyLjA3LDIuMDcsMCwwLDEsOSwuNTlsLjA4LjA4LDkuNzcsOS43Ny4wOC4wN2EyLjE5LDIuMTksMCwwLDEsLjUyLDIsMi4xNCwyLjE0LDAsMCwxLS41MiwxbC0uMDcuMDctOS44LDkuNzVhLjMuMywwLDAsMS0uMDguMDhBMi4xMSwyLjExLDAsMCwxLDcuNTEsMjRhMi4xMywyLjEzLDAsMCwxLTIuMTUtMi4xMywyLjA5LDIuMDksMCwwLDEsLjU4LTEuNDhaIj48L3BhdGg+PC9zdmc+Cg==');
}


// ui-kit-pagination-active
.ui-kit-pagination-active a,
.ui-kit-pagination-active a:hover,
.ui-kit-pagination-active a:focus,
.ui-kit-pagination-active a:active {
  background: $color-secondary;
  color: $color-white;
}


// ui-kit-pagination-disabled
.ui-kit-pagination-disabled {
  opacity: 0;
  pointer-events: none;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-pagination
  .ui-kit-pagination a {
    height: ($size-icon-medium * 2);
    line-height: ($size-icon-medium * 2);
    margin: 0 ($spacing-smaller / 4);
    width: ($size-icon-medium * 2);
  }


  // screen-large:ui-kit-pagination-button
  .ui-kit-pagination li:not(.ui-kit-pagination-active):not(.ui-kit-pagination-button) {
    display: block;
  }

  .ui-kit-pagination-button a {
    height: (($size-icon-medium * 2) - 2px);
    margin: 0;
    width: (($size-icon-medium * 2) - 2px);
  }

  .ui-kit-pagination-button a:hover {
    background: $color-secondary;
    border-color: $color-secondary;
    color: $color-white;
  }

  .ui-kit-pagination-button a:hover::before {
    opacity: 0;
  }

  .ui-kit-pagination-button a:hover::after {
    opacity: 1;
  }

  .ui-kit-pagination-button:first-of-type {
    margin-right: $spacing-medium;
  }

  .ui-kit-pagination-button:last-of-type {
    margin-left: $spacing-medium;
  }

}
