// _skeleton.scss

// ui-kit-skeleton
// ui-kit-skeleton-circle
// ui-kit-skeleton-circle-size
// ui-kit-skeleton-calendar
// ui-kit-skeleton-line
// ui-kit-skeleton-line-size
// ui-kit-skeleton-animation


// ui-kit-skeleton
.ui-kit-skeleton {
  overflow: hidden;
  position: relative;
}


// ui-kit-skeleton-circle
.ui-kit-skeleton-circle {
  animation: ui-kit-skeleton-animation 2s linear infinite;
  background: linear-gradient(90deg, rgba($color-black, 0) 0, rgba($color-black, 0) 30%, rgba($color-black, .04) 50%, rgba($color-black, 0) 70%, rgba($color-black, 0) 100%), $color-grey-lighter;
  background-size: 200% 200%;
  border-radius: 100%;
}


// ui-kit-skeleton-circle-size
.ui-kit-skeleton-circle-medium {
  height: ($size-icon-medium * 2);
  width: ($size-icon-medium * 2);
}

.ui-kit-skeleton-circle-small {
  height: $size-icon-larger;
  width: $size-icon-larger;
}


// ui-kit-skeleton-calendar
.ui-kit-skeleton-calendar {
  animation: ui-kit-skeleton-animation 2s linear infinite;
  background: linear-gradient(90deg, rgba($color-black, 0) 0, rgba($color-black, 0) 30%, rgba($color-black, .04) 50%, rgba($color-black, 0) 70%, rgba($color-black, 0) 100%), $color-grey-lighter;
  background-size: 200% 200%;
  border-radius: $border-radius-medium;
  height: 80px;
  width: 80px;
}


// ui-kit-skeleton-line
.ui-kit-skeleton-line {
  animation: ui-kit-skeleton-animation 2s linear infinite;
  background: linear-gradient(90deg, rgba($color-black, 0) 0, rgba($color-black, 0) 30%, rgba($color-black, .04) 50%, rgba($color-black, 0) 70%, rgba($color-black, 0) 100%), $color-grey-lighter;
  background-size: 200% 200%;
  border-radius: $border-radius-small;
  height: $spacing-smaller;
}

.ui-kit-skeleton-line + .ui-kit-skeleton-line {
  margin-top: $spacing-small;
}


// ui-kit-skeleton-line-size
.ui-kit-skeleton-line-10 {
  width: 10%;
}

.ui-kit-skeleton-line-20 {
  width: 20%;
}

.ui-kit-skeleton-line-30 {
  width: 30%;
}

.ui-kit-skeleton-line-40 {
  width: 40%;
}

.ui-kit-skeleton-line-50 {
  width: 50%;
}

.ui-kit-skeleton-line-60 {
  width: 60%;
}

.ui-kit-skeleton-line-70 {
  width: 70%;
}

.ui-kit-skeleton-line-80 {
  width: 80%;
}

.ui-kit-skeleton-line-90 {
  width: 90%;
}

.ui-kit-skeleton-line-100 {
  width: 100%;
}

// ui-kit-skeleton-animation
@keyframes ui-kit-skeleton-animation {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}
