// _overlay.scss

// ui-kit-overlay
// ui-kit-overlay-container
// ui-kit-overlay-background
// ui-kit-overlay-wrapper
// ui-kit-overlay-close
// ui-kit-overlay-visible
// screen-medium
// screen-medium:ui-kit-overlay-wrapper
// screen-large
// screen-large:ui-kit-overlay-container
// screen-large:ui-kit-overlay-wrapper
// screen-large:ui-kit-overlay-close


// ui-kit-overlay
.ui-kit-overlay {
  color: $color-primary;
  height: 100%;
  left: 0;
  min-width: $size-minwidth;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transform: translate(0, -100%);
  width: 100%;
  z-index: 5;
}


// ui-kit-overlay-container
.ui-kit-overlay-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  min-height: 100%;
  padding: $spacing-medium;
  position: relative;
}


// ui-kit-overlay-background
.ui-kit-overlay-background {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .3s ease-in-out;
  width: 100%;
}


// ui-kit-overlay-wrapper
.ui-kit-overlay-wrapper {
  background: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-normal;
  box-sizing: border-box;
  margin: 0 auto;
  padding: ($spacing-larger + $spacing-smaller) $spacing-small $spacing-large;
  position: relative;
  width: 100%;
}


// ui-kit-overlay-close
.ui-kit-overlay-close {
  position: absolute;
  right: ($spacing-smaller / 2);
  top: ($spacing-smaller / 2);
}


// ui-kit-overlay-visible
.ui-kit-overlay-visible {
  transform: translate(0, 0);
}

.ui-kit-overlay-visible .ui-kit-overlay-background {
  opacity: 1;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-overlay-wrapper
  .ui-kit-overlay-wrapper {
    padding: ($spacing-large * 2) ($spacing-larger + $spacing-smaller) ($spacing-larger + $spacing-smaller);
    width: $size-large;
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-overlay-container
  .ui-kit-overlay-container {
    padding: ($spacing-large + $spacing-medium) 0;
  }


  // screen-large:ui-kit-overlay-wrapper
  .ui-kit-overlay-wrapper {
    margin-top: -($spacing-large);
  }


  // screen-large:ui-kit-overlay-close
  .ui-kit-overlay-close {
    right: $spacing-small;
    top: $spacing-small;
  }

}
