// _input-checkbox-small.scss

// ui-kit-form:input-checkbox-small


// ui-kit-form:input-checkbox-small
.ui-kit-form .input-checkbox-small label {
  padding-left: ($spacing-smaller + 18px);
}

.ui-kit-form .input-checkbox-small span::before {
  height: 16px;
  left: 0;
  top: 3px;
  width: 16px;
}
