// _input-textarea-count.scss

// ui-kit-form:input-textarea-count


// ui-kit-form:input-textarea-count
.ui-kit-form .input-textarea-count {
  position: relative;
}

.ui-kit-form .input-textarea-count textarea {
  padding-bottom: round($font-size-small * $ratio-medium) + ($spacing-smaller * 2);
}

.ui-kit-form .input-textarea-count .counter {
  bottom: $spacing-smaller;
  color: $color-grey-dark;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-medium);
  position: absolute;
  right: $spacing-small;
}
