// _input-radio-time.scss

// ui-kit-form:input-radio-time
// screen-medium
// screen-medium:ui-kit-form:input-radio-time
// screen-large
// screen-large:ui-kit-form:input-radio-time


// ui-kit-form:input-radio-time
.ui-kit-form .input-radio-time {
  display: inline-block;
  margin: $spacing-smaller;
  text-align: center;
  width: calc(33.33% - (#{$spacing-smaller} * 2));
}

.ui-kit-form .input-radio-time + .input-radio-time {
  margin-top: $spacing-smaller;
}

.ui-kit-form .input-radio-time span {
  background: $color-white;
  border-radius: $border-radius-large;
  box-shadow: $box-shadow-normal;
  box-sizing: border-box;
  color: $color-primary;
  display: block;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-medium);
  padding: $spacing-smaller 0;
  transition: background .3s ease-in-out, box-shadow .3s ease-in-out, color .3s ease-in-out;
}

.ui-kit-form .input-radio-time span::before {
  content: none;
}

.ui-kit-form .input-radio-time label {
  display: block;
  padding: 0;
}

.ui-kit-form .input-radio-time input:checked + span {
  background: $color-accent;
  border-color: $color-accent;
  box-shadow: none;
  color: $color-white;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:ui-kit-form:input-radio-time
  .ui-kit-form .input-radio-time {
    width: 90px;
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-form:input-radio-time
  .ui-kit-form .input-radio-time label:hover span {
    background: $color-accent;
    border-color: $color-accent;
    color: $color-white;
  }

  .ui-kit-form .input-radio-time label:hover input:checked + span {
    box-shadow: none;
  }

}
