// _markup.scss

// ui-kit-markup
// ui-kit-markup:headings
// ui-kit-markup:links
// ui-kit-markup:lists
// ui-kit-markup:tables
// screen-large
// screen-large:ui-kit-markup:links


// ui-kit-markup
.ui-kit-markup {
  color: $color-primary;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}

.ui-kit-markup * + * {
  margin-top: $spacing-small;
}

.ui-kit-markup * + h1,
.ui-kit-markup h1 + * {
  margin-top: $spacing-medium;
}

.ui-kit-markup * + h2,
.ui-kit-markup * + h3,
.ui-kit-markup * + h4,
.ui-kit-markup * + h5,
.ui-kit-markup * + h6, {
  margin-top: $spacing-medium;
}


// ui-kit-markup:headings
.ui-kit-markup h1 {
  font-size: $font-size-larger;
  font-weight: 900;
  line-height: round($font-size-larger * $ratio-small);
}

.ui-kit-markup h2 {
  font-size: $font-size-large;
  font-weight: 900;
  line-height: round($font-size-large * $ratio-small);
}

.ui-kit-markup h3 {
  font-size: $font-size-medium;
  font-weight: 900;
  line-height: round($font-size-medium * $ratio-medium);
}

.ui-kit-markup h4,
.ui-kit-markup h5,
.ui-kit-markup h6 {
  font-weight: 400;
}

.ui-kit-markup h4 {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}

.ui-kit-markup h5,
.ui-kit-markup h6 {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-large);
}


// ui-kit-markup:links
.ui-kit-markup a {
  color: $color-secondary;
  font-weight: 700;
}

.ui-kit-markup a:focus,
.ui-kit-markup a:active {
  color: darken($color-secondary, $percentage-hover);
}


// ui-kit-markup:lists
.ui-kit-markup ul,
.ui-kit-markup ol {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.ui-kit-markup li {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  list-style-type: none;
  margin: 0 0 0 $spacing-medium;
  padding: 0;
  position: relative;
}

.ui-kit-markup li + li {
  margin-top: $spacing-smaller;
}

.ui-kit-markup ul li::before {
  background: $color-accent;
  border-radius: 50%;
  content: '';
  height: $size-icon-bull;
  left: -($spacing-medium + $size-icon-bull) / 2;
  position: absolute;
  top: ((round($font-size-medium * $ratio-medium) - $size-icon-bull) / 2);
  width: $size-icon-bull;
}

.ui-kit-markup ol li {
  list-style-type: decimal;
}


// ui-kit-markup:tables
.ui-kit-markup table,
.ui-kit-markup th,
.ui-kit-markup td {
  border: 1px solid $color-grey;
}

.ui-kit-markup table {
  border-collapse: collapse;
  width: 100%;
}

.ui-kit-markup th,
.ui-kit-markup td {
  padding: $spacing-smaller;
}

.ui-kit-markup th {
  font-weight: 700;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-markup:links
  .ui-kit-markup a:hover {
    color: lighten($color-secondary, $percentage-hover);
  }

  .ui-kit-markup a:focus,
  .ui-kit-markup a:active {
    color: darken($color-secondary, $percentage-hover);
  }

}
