// _avatar-medium.scss

// ui-kit-avatar-medium


// ui-kit-avatar-medium
.ui-kit-avatar-medium {
  font-size: $font-size-small;
  height: $size-avatar-medium;
  line-height: round($font-size-small * $ratio-small);
  width: $size-avatar-medium;
}
