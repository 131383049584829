// _tooltip.scss

// ui-kit-tooltip
// ui-kit-tooltip-dropdown
// screen-large
// screen-large:ui-kit-tooltip:hover


// ui-kit-tooltip
.ui-kit-tooltip {
  display: block;
  position: relative;
}


// ui-kit-tooltip-dropdown
.ui-kit-tooltip-dropdown {
  background: $color-primary;
  border-radius: $border-radius-small;
  box-sizing: border-box;
  max-width: $size-small;
  min-width: ($size-small / 2);
  opacity: 0;
  padding: $spacing-smaller $spacing-small;
  position: absolute;
  text-align: center;
  transition: opacity .3s ease-in-out;
  visibility: hidden;
  z-index: -1;
}

.ui-kit-tooltip-dropdown p {
  color: $color-white;
  font-size: $font-size-smaller;
  font-weight: 700;
  line-height: round($font-size-smaller * $ratio-medium);
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-tooltip:hover
  .ui-kit-tooltip:hover > .ui-kit-tooltip-dropdown {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

}
