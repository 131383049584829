// _link-icon-medium.scss

// ui-kit-link-icon-medium


// ui-kit-link-icon-medium
.ui-kit-link-icon-medium svg {
  height: $size-icon-medium;
  width: $size-icon-medium;
}
