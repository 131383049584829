// _icon-large.scss

// ui-kit-icon-large


// ui-kit-icon-large
.ui-kit-icon-large,
.ui-kit-icon-large svg {
  height: $size-icon-large;
  width: $size-icon-large;
}
