// _card-contextual.scss

// ui-kit-card-contextual
// ui-kit-card-contextual-icon
// ui-kit-card-contextual-active
// ui-kit-card-contextual-dropdown
// ui-kit-card-contextual-content
// screen-large
// screen-large:ui-kit-card-contextual-dropdown


// ui-kit-card-contextual
.ui-kit-card-contextual {
  position: relative;
}


// ui-kit-card-contextual-icon
// ui-kit-card-contextual-active
.ui-kit-card-contextual-active svg * {
  fill: $color-primary;
}


// ui-kit-card-contextual-dropdown
.ui-kit-card-contextual-dropdown {
  position: absolute;
  right: -($spacing-smaller);
  top: 48px;
  width: 230px;
  z-index: 1;
}


.ui-kit-card-contextual-dropdown a {
  color: $color-primary;
  display: block;
  font-weight: 700;
  padding: $spacing-smaller $spacing-medium;
  position: relative;
  transition: background .3s ease-in-out;
}

.ui-kit-card-contextual-dropdown::before {
  background: $color-white;
  border-left: 1px solid $color-grey-light;
  border-top: 1px solid $color-grey-light;
  content: '';
  height: 14px;
  position: absolute;
  right: 22px;
  top: -7px;
  transform: rotate(45deg);
  width: 14px;
}


// ui-kit-card-contextual-content
.ui-kit-card-contextual-content {
  background: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-normal;
  overflow: hidden;
}


// screen-large
@media #{$screen-large} {

  // screen-large:ui-kit-card-contextual-dropdown
  .ui-kit-card-contextual-dropdown {
    right: -($spacing-medium);
    top: 38px;
  }

  .ui-kit-card-contextual-dropdown::before {
    right: 28px;
  }

  .ui-kit-card-contextual-dropdown a:hover {
    background: $color-grey-light;
    color: $color-primary;
  }

}
