// _input-checkbox.scss

// ui-kit-form:input-checkbox
// ui-kit-form:input-checkbox:focus


// ui-kit-form:input-checkbox
.ui-kit-form .input-checkbox + .input-checkbox {
  margin-top: $spacing-small;
}

.ui-kit-form .input-checkbox label {
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 0;
  padding-right: 0;
  position: relative;
}

.ui-kit-form .input-checkbox input,
.ui-kit-form .input-checkbox span::before {
  position: absolute;
}

.ui-kit-form .input-checkbox input {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  padding: 0;
  top: auto;
  width: 1px;
}

.ui-kit-form .input-checkbox span::before {
  background: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-small;
  box-sizing: content-box;
  content: '';
  transition: border .3s ease-in-out, background-color .3s ease-in-out;
}

.ui-kit-form .input-checkbox input:checked + span::before {
  background-color: $color-accent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjMuMzcsNi4yOSwxMS45MywyMWEyLjg3LDIuODcsMCwwLDEtMy44Ni40NGwtMi0xLjYzTDEsMTQuNjVBMi42MiwyLjYyLDAsMCwxLC41NiwxMWEyLjU0LDIuNTQsMCwwLDEsMy41OC0uNDFsNiw1LjE3TDE5LjQ0LDNBMi41NCwyLjU0LDAsMCwxLDIzLDIuNTdhMi42MSwyLjYxLDAsMCwxLC4zMywzLjcyWiIvPjwvc3ZnPgo=');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  border-color: $color-accent;
}


// ui-kit-form:input-checkbox:focus
.ui-kit-form .input-checkbox input:focus + span::before {
  border-color: $color-accent;
}
